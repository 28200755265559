<template>
    <div>
		<el-dialog :title="title" :visible.sync="open" width="1000px" @close="commitOnClose">
			<el-form ref="saveForm" :model="saveData" label-width="130px" style="width:400px; padding-bottom:30px">
				<el-form-item label="返现配置名称" prop="templateName" :rules="[{ required: true, message: '请输入返现配置名称', trigger: 'blur' }]">
					<template v-if="isAdd"><el-input v-model="saveData.templateName" placeholder="请输入返现配置名称"/></template>
					<template v-else>{{cashbackTitle}}</template>
				</el-form-item>
			</el-form>
			<el-table
				ref="multipleTable"
				:data="agentActivityTemplate"
				tooltip-effect="dark"
				style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column
				v-if="isAdd"
				type="selection"
				label="全选"
				width="55">
				</el-table-column>
				<el-table-column
				label="活动名称"
				width="120" prop="activityName">
				</el-table-column>
				<el-table-column
				prop="policyName"
				label="费率政策"
				width="120">
				</el-table-column>
				<el-table-column
				label="激活返现">
					<template slot-scope="scope">
						<template  v-if="isAdd"><CashBackInput v-model="scope.row.activeRecash" :minVal="0" :maxVal="scope.row.maxActiveRecash"></CashBackInput></template>
						<template v-else>{{scope.row.activeRecash}}</template>
					</template>
					
				</el-table-column>
				<el-table-column
				label="重复激活返现">
					<template slot-scope="scope">
						<template  v-if="isAdd"><CashBackInput v-model="scope.row.repeatActiveRecash" :minVal="0" :maxVal="scope.row.maxRepeatActiveRecash"></CashBackInput></template>
						<template v-else>{{scope.row.repeatActiveRecash}}</template>
					</template>
				</el-table-column>
				<el-table-column
				label="达标返现1">
					<template slot-scope="scope">
						<template  v-if="isAdd"><CashBackInput v-model="scope.row.standardRecash1" :minVal="0" :maxVal="scope.row.maxStandardRecash1"></CashBackInput></template>
						<template v-else>{{scope.row.standardRecash1}}</template>
					</template>
					
				</el-table-column>
				<el-table-column
				label="达标返现2">
					<template slot-scope="scope">
						<template  v-if="isAdd"><CashBackInput v-model="scope.row.standardRecash2" :minVal="0" :maxVal="scope.row.maxStandardRecash2"></CashBackInput></template>
						<template v-else>{{scope.row.standardRecash2}}</template>
					</template>
					
				</el-table-column>
				<el-table-column
				label="达标返现3">
					<template slot-scope="scope">
						<template  v-if="isAdd"><CashBackInput v-model="scope.row.standardRecash3" :minVal="0" :maxVal="scope.row.maxStandardRecash3"></CashBackInput></template>
						<template v-else>{{scope.row.standardRecash3}}</template>
					</template>
					
				</el-table-column>
				<el-table-column
				label="sim流量费返现">
					<template slot-scope="scope" v-if="isAdd">
						<template  v-if="isAdd"><CashBackInput v-model="scope.row.flowRecash" :minVal="0" :maxVal="scope.row.maxFlowRecash"></CashBackInput></template>
						<template v-else>{{scope.row.flowRecash}}</template>
					</template>
					
				</el-table-column>
			</el-table>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="checkForm">{{translate('FROM.SUBMIT')}}</el-button>
				<el-button type="primary" @click="cancel">{{translate('FROM.CANCEL')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {AgentApi} from '@/api';
	import CashBackInput from '@/components/cashBackInput'
	export default {
		name: "CustomForm",
		props: {
			dicts: {
				type: Object,
				default: function () {
					return {}
				}
			},
			isAdd: {
				type: Boolean,
				default: true,
			},
			selectRow: {
				type: Object,
				default: function () {
					return {}
				},
			},
			onFresh: {
				type: Boolean,
				default: false
			},
			templateNo:{
				type: Number,
				default:0
			},
			cashbackTitle:{
				tyee: String,
				default: ''
			}
		},
		components:{
			CashBackInput
		},
		data() {
			return {
				title: '',
				saveData: {},
				open: false,
				parentIdReadOnly: false,
				agentActivityTemplate:'',
			}
		},
		watch: {
			onFresh: function (value) {
				this.open = value;
				if (value) {
					this.resetData();
					if(!this.isAdd){
						AgentApi.agentActivityTemplate.detail(this.templateNo)
						.then(res=>{
							if(res.success){
								this.agentActivityTemplate = res.data
							}
						})
					} else{
						AgentApi.agentActivityTemplate.beforeActivitySave()
							.then(res=>{
								if(res.success){
									this.agentActivityTemplate = res.data
								}
							})
					}
				}
			}
		},
		methods: {
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			async resetData() {
				if (this.isAdd){
					this.title = '新增返现配置';
					this.parentIdReadOnly = !!this.selectRow.menuId;
					this.saveData = {parentId: this.selectRow && this.selectRow.menuId};
				}else {
					this.saveData = this.selectRow;
					this.isAdd = false;
					this.title = '查看返现配置';
				}
			},
			async submitForm() {
				let params = {agentActivityInfoDtoList:[],templateName: this.saveData.templateName}
				params.agentActivityInfoDtoList = this.multipleSelection
				let result = (this.isAdd) ? (await AgentApi.agentActivityTemplate.save(params)) : '';
				if (result.success) {
					this.Message.success(this.translate(this.isAdd ? 'FROM.ADD_SUCCESS' : 'FROM.UPDATE_SUCCESS'));
					this.commitOnClose();
				}
			},
			checkForm(){
				this.$refs.saveForm.validate((valid) => {
					if (valid) {
						this.submitForm()
					} else {
						return false;
					}
					});
			},
			cancel() {
				this.saveData = {};
				this.commitOnClose();
			},
			commitOnClose() {
				this.$emit('on-close');
			}
		}
	};
</script>

<style scoped>

</style>
